/* 父容器设置 flex 布局 */
.json-dialog-box {
  display: flex;
  flex-direction: column;
  min-width: 80%;
  height: 90vh;
  /* 确保 Dialog 高度占满 */
  background-color: #4c4d53;
  justify-content: center;
}
/* Dialog 内容区域设置 flex 布局 */
.json-dialog-box .next-dialog-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* 撑满剩余高度 */
  overflow: hidden;
  /* 防止内容溢出 */
}
/* 工具栏固定高度 */
.json-tool {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.json-tool > * {
  margin-right: 10px;
  /* 每个子组件右边距为 10px */
}
.error-json-tip {
  display: flex;
  flex: 1;
  /* 撑满剩余高度 */
  overflow: hidden;
  /* 防止内容溢出 */
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 26px;
}
.error-json-tip .next-icon {
  margin-right: 10px;
  /* 设置 Icon 和内容之间的间距 */
}
/* JsonModal 自动充满剩余高度 */
.json-dialog-modal {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* 撑满剩余高度 */
  overflow: hidden;
  /* 防止内容溢出 */
}
/* JsonViewer 设置为 flex 布局 */
.json-viewer {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* 撑满剩余高度 */
  overflow: hidden;
  /* 防止内容溢出 */
  line-height: 1em;
  text-align: left;
  font-size: 14px;
  padding: 0 20px;
  width: 100%;
}
/* 表达式容器 */
.expressionContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #8d8c8c;
}
/* 表达式输入框 */
.expression {
  min-width: calc(100% - 480px);
  margin-right: 5px;
}
/* JSON 内容区域 */
.json-content {
  flex: 1;
  /* 撑满剩余高度 */
  width: 100%;
  padding: 0 20px;
  overflow-y: auto;
  /* 内容超出时显示滚动条 */
  border: 1px solid #ccc;
}
/* JSON 节点样式 */
.json-node .object-brackets {
  color: #d5bd1d;
  padding: 0;
  font-weight: 700;
  position: relative;
}
.json-node .array-brackets {
  color: #590c87;
  padding: 0;
  position: relative;
}
.json-node .object-brackets::before,
.json-node .array-brackets.show::before {
  content: '';
  position: absolute;
  top: -2px;
  /* 调整上边距 */
  right: -30px;
  /* 调整右边距 */
  bottom: -2px;
  /* 调整下边距 */
  left: -2px;
  /* 调整左边距 */
  pointer-events: auto;
  /* 确保伪元素可以触发事件 */
}
.json-node .json-collapsed {
  border-radius: 8px;
  margin-left: -30px;
}
.json-node .checkbox-hide {
  display: none;
}
.json-node .checkbox-show {
  display: inline-block;
  margin-left: 10px;
}
.json-node .arrayKey-input,
.json-node .arrayKey-select {
  width: 120px;
}
/* JSON 叶子节点样式 */
.json-leaf {
  cursor: pointer;
  /* 根据不同数据类型展示不同颜色 */
}
.json-leaf .json-value {
  padding: 0 8px;
  position: relative;
}
.json-leaf .json-value::before {
  content: '';
  position: absolute;
  top: -2px;
  /* 调整上边距 */
  right: -30px;
  /* 调整右边距 */
  bottom: -2px;
  /* 调整下边距 */
  left: -30px;
  /* 调整左边距 */
  pointer-events: auto;
  /* 确保伪元素可以触发事件 */
}
.json-leaf .booleanData {
  color: #4169e1;
}
.json-leaf .numberData {
  color: #ff8c00;
}
.json-leaf .stringData {
  color: #2ecc71;
}
.json-leaf .nullData {
  color: #8b4513;
}
.json-leaf .undefinedData {
  color: #a9a9a9;
}
.json-leaf .json-value:hover {
  background-color: #d0f8f8;
}
/* JSON 子节点样式 */
.json-children {
  margin-left: 20px;
}
/* JSON 键样式 */
.json-key {
  cursor: default;
  font-weight: 700;
  color: #ff8c00;
}
/* JSON 标签样式 */
.json-label {
  color: #fff;
}
/* 折叠样式 */
.collapsed {
  display: none;
}
/* 数组键容器样式 */
.arrayKeyContainer > .next-icon.next-medium:before,
.arrayKeyContainer .next-icon.next-medium .next-icon-remote {
  font-size: 16px;
}
.arrayKeyContainer > .next-icon {
  color: #590c87;
}
.arrayKeyContainer > .next-icon.hide {
  color: #ccc;
}
.arrayKeyContainer > .next-icon:hover {
  color: #ccc;
}
/* 图标样式 */
.json-dialog-icon {
  color: #fff;
  cursor: pointer;
}
/* Select下拉样式 */
.json-select.next-overlay-inner {
  width: auto !important;
}
.json-select .next-menu.next-ver .next-menu-item {
  padding: 0 30px !important;
}
.checkbox-show.next-checkbox-wrapper input[type='checkbox'] {
  opacity: 0.7 !important;
}
